import React from "react"
import styled from "styled-components"
import { IsDesktop, IsMobile } from "./utils"
import Nav from "./nav"
import MobileNav from "./mobileNav"
import DesignTokens from "./designTokens"

const LandingHeader = ({ title, locale }) => (
  <Wrapper>
    <IsDesktop>
      <Nav locale={locale} landing />
    </IsDesktop>
    <IsMobile>
      <MobileNav locale={locale} />
    </IsMobile>
  </Wrapper>
)
const Wrapper = styled.div`
  background-color: ${props =>
    props.theme === "light"
      ? DesignTokens.colors.white
      : DesignTokens.colors.secondary[500]};
  color: ${props =>
    props.theme === "light"
      ? DesignTokens.colors.primary[500]
      : DesignTokens.colors.white};
  @media screen and (max-width: 767px) {
    background-size: 400%;
    background-position: center;
  }
`

export default LandingHeader
