import React from "react"
import {
  Box,
  Container,
  Divider,
  Grid,
  Image,
  Text,
} from "@theme-ui/components"

const FeaturesBlock = ({ content }) => (
  <Box
    py={[5, 6, 6]}
    sx={{ borderBottom: "1px solid", borderColor: "#eeeeee" }}
  >
    <Container>
      <Box mb={[5, 6]} sx={{ textAlign: "left" }}>
        <Text variant="h2" as="h2" sx={{ color: "primary" }}>
          {content.title}
        </Text>
        <Text variant="h5" as="p" sx={{ fontWeight: 400 }}>
          {content.subtitle}
        </Text>
      </Box>
      <Grid
        columns={[1, 2, 3]}
        gap={48}
        sx={{
          padding: [3, 5],
          backgroundColor: "#fafafa",
          borderRadius: ["small", "lg"],
        }}
      >
        {content.benefits.map(benefit => (
          <Box key={benefit.id}>
            {benefit.icon && (
              <Image
                src={benefit.icon.url}
                alt={benefit.icon.alt}
                mb={2}
                sx={{ height: "60px" }}
              />
            )}
            <Text as="h3">{benefit.title}</Text>
            <Divider
              sx={{
                borderBottom: "1px dashed",
                borderColor: "dark",
                width: "50%",
                mb: 3,
              }}
            />
            {benefit.text && (
              <Box dangerouslySetInnerHTML={{ __html: benefit.text }} />
            )}
          </Box>
        ))}
      </Grid>
    </Container>
  </Box>
)

export default FeaturesBlock
