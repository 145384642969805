import React, { useState } from "react"
import axios from "axios"
import styled from "styled-components"
import DesignTokens from "./designTokens"

const ContactForm = () => {
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [hutk, setHutk] = useState(
    typeof window !== "undefined"
      ? document.cookie.replace(
          /(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        )
      : ""
  )
  const [href, setHref] = useState(
    typeof window !== "undefined" ? document.location.href : ""
  )
  const [title, setTitle] = useState(
    typeof window !== "undefined" ? window.document.title : ""
  )

  const handleSubmit = event => {
    event.preventDefault()
    setLoading(true)
    const options = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
    const data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      hutk: hutk,
      href: href,
      title: title,
    }
    axios
      .post(
        "https://hooks.zapier.com/hooks/catch/7165528/ojeoqpk/",
        data,
        options
      )
      .then(function (response) {
        setSuccess(true)
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
      })
    typeof window !== "undefined" &&
      window.gtag("event", "Form", "Submit", "Demo")
  }

  return (
    <Wrapper id="form">
      <form method="post" name="download" onSubmit={handleSubmit}>
        <section>
          <label htmlFor="firstName">Nome</label>
          <input
            onChange={e => setFirstName(e.target.value)}
            type="text"
            name="firstName"
            id="firstName"
            value={firstName}
            required
          />
        </section>
        <section>
          <label htmlFor="lastName">Cognome</label>
          <input
            onChange={e => setLastName(e.target.value)}
            type="text"
            name="lastName"
            id="lastName"
            value={lastName}
            required
          />
        </section>
        <section>
          <label htmlFor="email">Email</label>
          <input
            onChange={e => setEmail(e.target.value)}
            type="email"
            name="email"
            id="email"
            value={email}
            required
          />
        </section>
        <section>
          <p>
            Dopo aver preso visione della presente informativa sulla privacy ai
            sensi dell'ART.13 D.Lgs. n.196 del 2003, a) Acconsento al
            trattamento dei dati personali al fine esclusivo di avere
            informazioni riguardo i servizi relativi al presente sito web. Per
            completare la registrazione è necessaria l'autorizzazione al
            trattamento dei dati personali.
          </p>
        </section>
        <Checkbox>
          <input type="checkbox" id="privacy" name="privacy" required />
          <label htmlFor="privacy">Si, acconsento</label>
        </Checkbox>
        <section>
          {!success && (
            <input
              type="submit"
              value={loading ? `Caricamento...` : `Richiedi una Demo`}
            />
          )}
          {success && (
            <>
              <input type="submit" value="Richiesta inviata!" disabled />
              <img
                height="1"
                width="1"
                style={{ display: "none" }}
                alt=""
                src="https://px.ads.linkedin.com/collect/?pid=2943265&conversionId=3352090&fmt=gif"
              />
            </>
          )}
        </section>
      </form>
    </Wrapper>
  )
}

export default ContactForm

const Checkbox = styled.div`
  display: flex;
  flex-direction: row;
  input {
    margin-right: 0.5rem;
  }
  label {
    font-size: 14px;
    line-height: 1.1;
    margin-bottom: 0 !important;
  }
`

const Wrapper = styled.div`
  border-radius: ${DesignTokens.borderRadius};
  form {
    padding-top: 2rem;
    label {
      margin-bottom: 0.5rem;
      color: white;
      font-size: 14px;
    }
    input,
    textarea {
      padding: ${DesignTokens.spacing[4]};
      color: black;
      border: none;
      font-size: 1rem;
      border-radius: 0.25rem;
      background: white;
      border: 1px solid white;
    }

    input[type="submit"] {
      background: white;
      border: none;
      color: ${DesignTokens.colors.primary[500]};
      text-transform: uppercase;
      font-weight: bold;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      border-radius: 1000rem;
      cursor: pointer;
      &:hover,
      &:active {
        background: ${DesignTokens.colors.secondary[500]};
        color: white;
      }
      &:disabled {
        background: #4caf50;
        cursor: default;
        color: white;
      }
    }
    section {
      display: flex;
      flex-direction: column;
      p {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    & > * {
      margin-bottom: ${DesignTokens.spacing[4]};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
