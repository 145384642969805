import React from "react"
import { Box, Container, Grid, Text } from "@theme-ui/components"
import { StructuredText } from "react-datocms"
import {
  isHeading,
  isParagraph,
  renderRule,
} from "datocms-structured-text-utils"
import { LinkButtonTertiary } from "./button"
import ContactForm from "./contactForm"
import HubspotCalendar from "./hubspotCalendar"
import background from "../images/hero-image.jpg"

const LandingHeaderBlock = ({ content }) => (
  <Box
    sx={{
      color: "light",
      backgroundColor: "dark",
      backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      backgroundPosition: "top center",
    }}
    py={[2, 5]}
  >
    <Container>
      <Grid columns={[1, 1, "5fr 3fr"]} gap={52}>
        <Box pt={5}>
          <StructuredText
            data={content.body}
            customRules={[
              renderRule(
                isHeading,
                ({ adapter: { renderNode }, node, children, key }) => {
                  return renderNode(
                    () => {
                      switch (node.level) {
                        case 2:
                          return (
                            <Text
                              as={`h${node.level}`}
                              sx={{
                                fontSize: [3, 5, 5],
                                fontWeight: "normal",
                                color: "light",
                                mb: [4, 5],
                              }}
                            >
                              {children}
                            </Text>
                          )
                        default:
                          return (
                            <Text
                              as={`h${node.level}`}
                              variant={`h${node.level}`}
                            >
                              {children}
                            </Text>
                          )
                      }
                    },
                    { key },
                    children
                  )
                }
              ),
              renderRule(
                isParagraph,
                ({ adapter: { renderNode }, node, children, key }) => {
                  return renderNode(
                    () => {
                      return (
                        <Text
                          as="p"
                          sx={{ color: "light", fontSize: [2, 3], mb: [2, 3] }}
                        >
                          {children}
                        </Text>
                      )
                    },
                    { key },
                    children
                  )
                }
              ),
            ]}
            renderInlineRecord={({ record }) => {
              switch (record.__typename) {
                case "DatoCmsLandingPage":
                  return (
                    <LinkButtonTertiary to={`/${record.slug}`}>
                      {record.title}
                    </LinkButtonTertiary>
                  )
                default:
                  return null
              }
            }}
          />
        </Box>
        {content.poc === "form" ? (
          <Box
            sx={{ backgroundColor: "primary", px: 4, borderRadius: "default" }}
          >
            <ContactForm />
          </Box>
        ) : (
          <Box>
            <HubspotCalendar />
          </Box>
        )}
      </Grid>
    </Container>
  </Box>
)

export default LandingHeaderBlock
