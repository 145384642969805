import React from "react"
import { Box, Container, Text } from "@theme-ui/components"
import background from "../images/hero-image.jpg"

const BottomCta = ({ content }) => (
  <Box
    as="section"
    py={[5, 6, 6]}
    sx={{
      color: "light",
      backgroundColor: "dark",
      backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      backgroundPosition: "top center",
    }}
  >
    <Container>
      <Text as="p" variant="h2">
        {content.title}
      </Text>
      <Text as="p" variant="h4">
        {content.subtitle}
      </Text>
      <Box
        sx={{
          display: "inline-block",
          paddingX: 5,
          paddingY: 4,
          border: "none",
          borderRadius: "full",
          color: "light",
          backgroundColor: "primary",
          fontWeight: 800,
          fontSize: 3,
          mt: 5,
          cursor: "pointer",
          "&:hover": {
            color: "primary",
            backgroundColor: "light",
          },
        }}
        onClick={() => window.scrollTo(0, 0)}
      >
        {content.cta}
      </Box>
    </Container>
  </Box>
)

export default BottomCta
