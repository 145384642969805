import React, { useEffect } from "react"
import { Box } from "theme-ui"

const HubspotCalendar = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])
  return (
    <Box>
      <div
        class="meetings-iframe-container"
        data-src="https://app.hubspot.com/meetings/andrea388?embed=true"
      ></div>
    </Box>
  )
}

export default HubspotCalendar
