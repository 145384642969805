import React from "react"
import { Box, Container, Grid, Image, Text } from "@theme-ui/components"
import { StructuredText } from "react-datocms"
import {
  isHeading,
  isParagraph,
  renderRule,
} from "datocms-structured-text-utils"
import { ExternalLinkButton } from "./button"

const DownloadBlock = ({ content }) => (
  <Box py={[2, 5]}>
    <Container>
      <Grid columns={[1, 1, "5fr 3fr"]} gap={52}>
        <Box
          sx={{ "p:first-child": { fontSize: 4 }, "p:last-child": { mb: 0 } }}
        >
          <StructuredText
            data={content.body}
            customRules={[
              renderRule(
                isHeading,
                ({ adapter: { renderNode }, node, children, key }) => {
                  return renderNode(
                    () => {
                      return (
                        <Text as={`h${node.level}`} variant={`h${node.level}`}>
                          {children}
                        </Text>
                      )
                    },
                    { key },
                    children
                  )
                }
              ),
              renderRule(
                isParagraph,
                ({ adapter: { renderNode }, node, children, key }) => {
                  return renderNode(
                    () => {
                      return (
                        <Text as="p" sx={{ color: "dark", fontSize: 2, mb: 4 }}>
                          {children}
                        </Text>
                      )
                    },
                    { key },
                    children
                  )
                }
              ),
            ]}
          />
          <Box mt={5}>
            <ExternalLinkButton
              target="blank"
              rel="nofollow noopener"
              href={content.pdf.url}
            >
              Scarica il caso studio
            </ExternalLinkButton>
          </Box>
        </Box>
        <Box>
          {content.image && (
            <Image
              src={content.image.url}
              alt={content.image.alt}
              sx={{ borderRadius: "lg" }}
            />
          )}
        </Box>
      </Grid>
    </Container>
  </Box>
)

export default DownloadBlock
