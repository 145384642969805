import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Box } from "theme-ui"
import LandingHeader from "../components/landingHeader"
import LandingHeaderBlock from "../components/landingHeaderBlock"
import FeaturesBlock from "../components/featuresBlock"
import DownloadBlock from "../components/downloadBlock"
import BottomCta from "../components/bottomCta"
import LogoBlock from "../components/logoBlock"
import ImageGallery from "../components/imageGallery"
import LandingHeaderHubspot from "../components/landingHeaderHubspot"
import CtaBlock from "../components/ctaBlock"

const LandingPage = ({ data: { page } }) => {
  const locale = page.locale
  return (
    <Layout locale={locale} minimalFooter>
      <SEO title={page.title} lang={locale} />
      <LandingHeader title={page.title} locale={locale} minimal />
      <Box>
        {page.content.map(section => (
          <Box as="section" key={section.id}>
            {section.model.apiKey === "cta_block" && (
              <CtaBlock content={section} />
            )}
            {section.model.apiKey === "image_gallery" && (
              <ImageGallery images={section.images} />
            )}
            {section.model.apiKey === "landing_header" && (
              <LandingHeaderBlock content={section} />
            )}
            {section.model.apiKey === "landing_header_hubspot" && (
              <LandingHeaderHubspot content={section} />
            )}
            {section.model.apiKey === "logo_block" && (
              <LogoBlock images={section.images} />
            )}
            {section.model.apiKey === "features_block" && (
              <FeaturesBlock content={section} />
            )}
            {section.model.apiKey === "download_block" && (
              <DownloadBlock content={section} />
            )}
            {section.model.apiKey === "bottom_cta_block" && (
              <BottomCta content={section} />
            )}
          </Box>
        ))}
      </Box>
    </Layout>
  )
}

export default LandingPage

export const query = graphql`
  query LandingPageQuery($originalId: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsLandingPage(
      originalId: { eq: $originalId }
      locale: { eq: $locale }
    ) {
      title
      text
      locale
      isPage
      formText
      _allSlugLocales {
        value
        locale
      }
      content {
        ... on DatoCmsCtaBlock {
          id
          backgroundColor {
            hex
          }
          callToAction {
            ... on DatoCmsLink {
              ...LinkDetails
            }
            ... on DatoCmsExternalLink {
              id
              model {
                apiKey
              }
              anchorText
              url
              blank
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageGallery {
          ...GalleryDetails
        }
        ... on DatoCmsLandingHeader {
          id
          body {
            value
            links {
              __typename
              ... on DatoCmsLandingPage {
                id: originalId
                slug
                title
              }
            }
          }
          poc
          model {
            apiKey
          }
        }
        ... on DatoCmsLandingHeaderHubspot {
          id
          body {
            value
            links {
              __typename
              ... on DatoCmsLandingPage {
                id: originalId
                slug
                title
              }
            }
          }
          formId
          model {
            apiKey
          }
        }
        ... on DatoCmsDownloadBlock {
          id
          body {
            value
          }
          pdf {
            url
          }
          image {
            url
            alt
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsLogoBlock {
          id
          images {
            url
            originalId
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsBottomCtaBlock {
          id
          title
          subtitle
          cta
          model {
            apiKey
          }
        }
        ... on DatoCmsFeaturesBlock {
          id
          title
          subtitle
          benefits {
            ... on DatoCmsBenefit {
              id
              model {
                apiKey
              }
              title
              text
              icon {
                url
                alt
              }
            }
          }
          model {
            apiKey
          }
        }
      }
    }
  }
`
